import React, { useState, useEffect, useReducer } from 'react'
import { Card, Grid, Button, Form, Input, Icon, Table, Modal, ButtonProps, InputOnChangeData, Popup, Segment, Dimmer, Loader, Dropdown } from 'semantic-ui-react';
import ReactDatePicker from 'react-datepicker'
import "react-datetime/css/react-datetime.css";
import ReactPaginate from "react-paginate";
import "../styles/Pagination.scss"
import '../styles/Logs.scss';
import { useNavigate } from "react-router-dom";
import { RequestPayload } from '../services/ApiClient/types'
import { transactionLogService } from '../services';
import { TransactionLogFilterModel, RouteStopView } from '../services/TransactionLogService/types';
import moment from 'moment';
import { formatAddress } from '../utils/addressHelper';
import { formatDateAndTime } from '../utils/datehelper';

const Logs = () => {
  const [pageNumber, setPageNumber] = useState(0)
  const [postsPerPage, setPostsPerPage] = useState(15)
  const [stopHistory, setStopHistory] = useState<RouteStopView[]>();
  const [searchDates, setSearchDates] = useState({ startDate: new Date(), endDate: new Date() })
  const [companiesAndStatuses, setCompaniesAndStatuses] = useState({ companies: [{}], statuses: [] })
  const [filterLogOpts, setFilterLogOpts] = useState({ company: 'All', status: 'All(default)' })
  const [searchValue, setSearchValue] = useState("")
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [modalData, setModalData] = useState({ id: "default", timestamp: "default" })

  let defaultFilter: TransactionLogFilterModel = {
    fromDate: new Date().toISOString(),
    fromHour: 0,
    fromMinute: 0,
    fromTimeofDay: "AM",
    fromDateTimeCombined: new Date().toISOString(),
    toDate: new Date().toISOString(),
    toHour: 11,
    toMinute: 59,
    toTimeOfDay: "PM",
    toDateTimeCombined: new Date().toISOString(),
    companyId: 0,
    status: "",
    searchText: "",
    statuses: [],
    companies: {}
  }
  let customTransactionLogView: Array<LogItem> = [{
    Id: 0,
    CourierName: "Default",
    CustomerName: "Default",
    Status: "Default",
    MsgIdentifier: 0,
    Timestamp: "Default",
    Details: null
  }]
  type LogItem = {
    Id: number;
    CourierName: string;
    CustomerName: string;
    Status: string;
    MsgIdentifier: number;
    Timestamp: string;
    Details: JSX.Element;
  };
  type PageChange = {
    selected: number
  }
  type Action =
    | { type: 'UPDATE_FROM'; payload: Date }
    | { type: 'UPDATE_TO'; payload: Date }
    | { type: 'SELECTED_COMPANY'; payload: { [key: string]: string } }
    | { type: 'SELECTED_STATUS'; payload: string }
    | { type: string; payload: any };

  const custom: Array<LogItem> = []
  const [customFilteredLogs, setCustomFilteredLogs] = useState<LogItem[]>(customTransactionLogView)
  const navigate = useNavigate();
  const [selectedLogs, setSelectedLogs] = useState([])
  const posts: Array<LogItem> = customFilteredLogs.slice(0, 150);
  const [filterModel, setFilterModel] = useState<TransactionLogFilterModel>(defaultFilter);
  const pagesVisited = pageNumber * postsPerPage
  type LogItemKey = keyof LogItem;
  const displayPosts = posts.slice(pagesVisited, pagesVisited + postsPerPage).map((post: LogItem, idx: number) => {
    return (
      <Table.Row key={idx} >
        {(Object.keys(post) as Array<keyof LogItem>).map((cellData: LogItemKey, idx: number) => {
          const tc = { textAlign: 'center' }
          const content = post[cellData] === post['Timestamp'] ? moment(post['Timestamp']).format('L LT') : post[cellData]
          return (
            <Table.Cell className='table-row-style' collapsing key={idx} style={tc} width={3}>
              {(typeof post[cellData]) === 'object' ? post[cellData] : <Popup content={content} trigger={<span style={{ fontSize: "1rem" }}>{content}</span>} />}
            </Table.Cell>
          );
        })}
      </Table.Row>
    )
  })

  const getLogFilter = () => {
    let payload: RequestPayload<null> = {
      payload: null
    };
    transactionLogService.GetTransactionLogFilter(payload)
      .then(response => {
        if (response.success) {
          setCompaniesAndStatuses({ companies: [response.result.companies], statuses: response.result.statuses })
        }
        else {
          console.error('Error pulling back log data', response.message);
        }
      });
  }

  const reducer = (state: TransactionLogFilterModel, action: Action) => {
    switch (action.type) {
      default:
        return state;
      case 'UPDATE_FROM':
        var fromOffset = new Date().getTimezoneOffset() - new Date(action.payload).getTimezoneOffset()
        var fromOffset2 = new Date(action.payload).getTimezoneOffset() - new Date().getTimezoneOffset()
        var fromCorrectedDate = moment(action.payload).subtract(fromOffset, 'minutes').format()
        setSearchDates(prev => ({
          ...prev, startDate: new Date(action.payload)
        }))
        setFilterModel(prev => ({
          ...prev, fromDate: moment(action.payload).subtract(fromOffset2, 'minutes').format(), fromHour: parseInt(moment(action.payload).subtract(fromOffset2, 'minutes').format('h')), fromMinute: new Date(action.payload).getMinutes(), fromTimeofDay: new Date(action.payload).getHours() < 12 ? "AM" : "PM", fromDateTimeCombined: moment(action.payload).subtract(fromOffset2, 'minutes').toISOString()
        }))
        return { ...state, fromDate: moment(fromCorrectedDate).toISOString(), fromHour: parseInt(moment(fromCorrectedDate).format('h')), fromMinute: new Date(fromCorrectedDate).getMinutes(), fromTimeofDay: new Date(fromCorrectedDate).getHours() < 12 ? "AM" : "PM", fromDateTimeCombined: moment(fromCorrectedDate).toISOString() }
      case 'UPDATE_TO':
        var toOffset = new Date().getTimezoneOffset() - new Date(action.payload).getTimezoneOffset()
        var toOffset2 = new Date(action.payload).getTimezoneOffset() - new Date().getTimezoneOffset()
        var toCorrectedDate = moment(action.payload).subtract(toOffset, 'minutes').format()
        setSearchDates(prev => ({
          ...prev, endDate: new Date(action.payload)
        }))
        setFilterModel(prev => ({
          ...prev, toDate: moment(action.payload).subtract(toOffset2, 'minutes').format(), toHour: parseInt(moment(action.payload).subtract(toOffset2, 'minutes').format('h')), toMinute: new Date(action.payload).getMinutes(), toTimeOfDay: new Date(action.payload).getHours() < 12 ? "AM" : "PM", toDateTimeCombined: moment(action.payload).subtract(toOffset2, 'minutes').toISOString()
        }))
        return { ...state, toDate: moment(toCorrectedDate).toISOString(), toHour: parseInt(moment(toCorrectedDate).format('h')), toMinute: new Date(toCorrectedDate).getMinutes(), toTimeOfDay: new Date(toCorrectedDate).getHours() < 12 ? "AM" : "PM", toDateTimeCombined: moment(toCorrectedDate).toISOString() }
      case 'SELECTED_COMPANY':
        if (action.payload.value === 0) {
          setFilterModel(prev => ({
            ...prev, companyId: 0
          }))
          return { ...state, companyId: 0 }
        } else {
          setFilterModel(prev => ({
            ...prev, companyId: parseInt(action.payload.key)
          }))
          return { ...state, companyId: action.payload.key };
        }
      case 'SELECTED_STATUS':
        setFilterModel(prev => ({
          ...prev, status: action.payload
        }))
        return { ...state, status: action.payload };
      case 'LOG_SEARCH':
        console.log(action.payload)
        setCustomFilteredLogs(action.payload[1])
        return { ...state }
    }
  }
  const [, dispatch] = useReducer(reducer, filterModel)
  const getStopHistory = (fileId: string) => {
    setIsLoading(true)
    let payload: RequestPayload<null> = {
      queryParams: {
        fid: fileId
      },
      payload: null
    };
    transactionLogService.GetStopHistory(payload)
      .then(response => {
        if (response.success) {
          console.log("stop History: ", response.result);
          setStopHistory(response.result)
          setIsLoading(false)
        }
        else {
          console.error('Error pulling back stop history', response.message);
          setIsLoading(false)
        }
      });
  }
  const messageDetailsClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: ButtonProps) => {
    getStopHistory(data.value)
    setIsOpen(true)
    setModalData(data.fullLog)
  }
  const getFilteredLogs = () => {
    let payload: RequestPayload<TransactionLogFilterModel> = {
      payload: filterModel
    };
    transactionLogService.GetFilteredLogs(payload)
      .then(response => {
        if (response.success) {
          response.result.map((log: any) =>
            custom.push({
              Id: log.id,
              CourierName: log.courierName,
              //CourierId: log.courierId,
              CustomerName: log.customerName,
              //CustomerId: log.customerId,
              Status: log.status,
              MsgIdentifier: log.file,
              Timestamp: log.timestamp,
              Details:
                <Button
                  fullLog={log}
                  value={log.ccpFile}
                  color='blue'
                  onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: ButtonProps) => messageDetailsClick(e, data)}>msg details</Button>
            })
          )
          selectedLogs.length > 0 ? setCustomFilteredLogs(selectedLogs) : setCustomFilteredLogs(custom)
        }
        else {
          console.error('Error pulling back logs', response.message);
        }
        setSelectedLogs([])
      });
  }
  const changeCompany = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: ButtonProps) => {
    setFilterLogOpts(prev => ({
      ...prev,
      company: data.value
    }))
    const selectedCompany = data.options.filter((x: ButtonProps) => x.value === data.value)
    dispatch({ type: 'SELECTED_COMPANY', payload: { key: selectedCompany[0].key, value: selectedCompany[0].value } })
  }
  const changeStatus = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: ButtonProps) => {
    setFilterLogOpts(prev => ({
      ...prev,
      status: data.value
    }))
    dispatch({ type: 'SELECTED_STATUS', payload: data.value })
  }
  useEffect(() => {
    if (localStorage.token === null || localStorage.token === undefined) {
      const navTo = `/`
      navigate(navTo)
    }
    else {
    }
  }, [navigate]);

  useEffect(() => {
    getLogFilter()
    getFilteredLogs()

    // eslint-disable-next-line
  }, [])

  const pageCount = Math.ceil(posts.length / postsPerPage);
  const changePage = ({ selected }: PageChange) => {
    setPageNumber(selected)
  }
  const changeFromDate = (date: Date) => {
    const uncorrectedDate = moment(date).format()
    dispatch({ type: 'UPDATE_FROM', payload: uncorrectedDate })
  }
  const changeToDate = (date: Date) => {
    const newDate = date
    dispatch({ type: 'UPDATE_TO', payload: newDate })
  }
  const logSearch = (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setSearchValue(data.value)
    if (data.value.length > 3) {
      customFilteredLogs.map((log: LogItem, idx: number) => {
        if (Object.entries(log)
          .find((([key, value]: any) =>
            (key === "CourierName" && value.toLowerCase().includes(searchValue.toLowerCase())) ||
            (key === "MsgIdentifier" && value.includes(searchValue))
          ))) {
          setSelectedLogs(prev => [...prev, log])
          return selectedLogs
        }
        return selectedLogs
      }
      )
    }
  }
  const opts = Object.entries(companiesAndStatuses.companies[0]).map(([key, value]: any) =>
    ({ key: key, text: value, value: value })
  )
  opts.push({ key: 6, text: "All", value: 0 })
  const opts2 = companiesAndStatuses.statuses.map((stat: string, idx: number) =>
    ({ key: idx, text: stat, value: stat })
  )
  opts2.push({ key: 12, text: "All", value: "" })
  // const excludedItems = ["podSignatureUrl", "userPreferences", "allExceptionTypes", "stopExceptions", "stopExceptionTypes", "hasException", "onTimeIcon", "onTimeColor"]

  return (
    <div className='logs-wrapper'>
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <Modal.Header style={{ textAlign: "center" }}>
          Selected ID: <span style={{ color: "blue" }}>{modalData.id}</span> &nbsp;
          Delivery Date: <span style={{ color: 'blue' }}>{moment(modalData.timestamp).format("MMM Do YYYY")}</span> &nbsp;
          Delivery Time: <span style={{ color: 'blue' }}>{moment(modalData.timestamp).format("LT")}</span>
        </Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <Table>
              {isLoading ? (
                <Table.Row>
                  <Table.Cell colSpan="100%">
                    <Segment>
                      <Dimmer active inverted>
                        <Loader inverted />
                      </Dimmer>
                    </Segment>
                  </Table.Cell>
                </Table.Row>
              ) : stopHistory === undefined || stopHistory === null ? (
                <Table.Row>
                  <Table.Cell colSpan="100%">N/A</Table.Cell>
                </Table.Row>
              ) : stopHistory.length === 0 ? (
                <Table.Row>
                  <Table.Cell colSpan="100%">No Stop History Information Available</Table.Cell>
                </Table.Row>
              ) : (
                stopHistory.map((route: RouteStopView, idx: number) => {
                  return <React.Fragment key={idx}>
                    <Table.Header >
                      <Table.Row>
                        <Table.HeaderCell style={{ backgroundColor: "#2d8fc4", textAlign: "center", color: "#fff" }} colSpan='2'> {route.routeIdExt} </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell><b>Courier</b></Table.Cell><Table.Cell>{route.courier}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell><b>Stop ID</b></Table.Cell><Table.Cell>{route.stopId}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell><b>Address</b></Table.Cell><Table.Cell>{formatAddress(route.location, true)}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell><b>Sequence</b></Table.Cell><Table.Cell>{route.sequence}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell><b>Pieces</b></Table.Cell><Table.Cell>{route.pieces}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell><b>Weight</b></Table.Cell><Table.Cell>{route.weight}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell><b>Reference</b></Table.Cell><Table.Cell>{route.reference === "" ? 'No references' : route.reference}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell><b>Post Date</b></Table.Cell><Table.Cell>{route.postdate === undefined ? '' : formatDateAndTime(route.postdate)}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell><b>Time Arrived</b></Table.Cell><Table.Cell>{route.timeArrived === undefined ? '' : formatDateAndTime(route.timeArrived)}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell><b>Elapsed Time</b></Table.Cell><Table.Cell>{route.elapsedTime}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell><b>Signature</b></Table.Cell><Table.Cell>{route.pODSignatureEncoded !== "" ? <img src={route.pODSignatureEncoded} alt="POD-Signature-Encoded" /> : "N/A"}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell><b>Exception Count</b></Table.Cell><Table.Cell>{route.systemExceptionCount}</Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </React.Fragment>
                }
                ))
              }
            </Table>
          </Modal.Description>
        </Modal.Content>
      </Modal>
      <Grid id="logs-grid">
        {/* Filters Column */}
        <Grid.Column mobile={16} tablet={16} computer={3} largeScreen={4} >
          <Card id="card">
            <Card.Content id="logs-card-content">
              <h1 className="logs-span"><Icon name="exchange" />Filter Transaction Logs</h1>
              <Form id="logs-form">
                <Form.Field id="logs-form-field">
                  <label >From</label>
                  <ReactDatePicker shouldCloseOnSelect={false} timeInputLabel='Time:' showTimeInput selected={searchDates.startDate} onChange={(date: Date) => changeFromDate(date)} />
                </Form.Field>
                <Form.Field id="logs-form-field">
                  <label>To</label>
                  <ReactDatePicker shouldCloseOnSelect={false} timeInputLabel='Time:' showTimeInput selected={searchDates.endDate} onChange={(date) => changeToDate(date)} />
                </Form.Field>
                <div id="logs-select">
                  <Form.Select placeholder='All' value={filterLogOpts.company} onChange={(e: any, data: any) => changeCompany(e, data)} label='Company' options={opts} />
                </div>

                <div id="logs-select">
                  <Form.Select placeholder='All' value={filterLogOpts.status} onChange={(e: any, data: any) => changeStatus(e, data)} label="Status" options={opts2} />
                </div>
                <Form.Field id="logs-form-field">
                  <label >Search for logs</label>
                  <Input focus placeholder='Enter text here' onChange={(e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => logSearch(e, data)} />
                </Form.Field>
              </Form>
              <Grid.Row className='apply-button' >
                <Button primary color='blue' id="logs-button" onClick={() => getFilteredLogs()}>
                  <Icon name="filter" />Apply Filter
                </Button>
              </Grid.Row>
            </Card.Content>
          </Card>
        </Grid.Column>

        {/* Table Column */}
        <Grid.Column mobile={16} tablet={16} computer={12} largeScreen={12} >
          <div className='logs-section-header'>
            <div className="logs-entries-container">
            </div>
            <h1><Icon name="exchange" />Transaction Logs</h1>
          </div>

          {/* Actual table of logs */}
          <div className='logs-table'>
            <div className="paginateandcount">
              <ReactPaginate
                previousLabel="Previous"
                nextLabel="Next"
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
              <span className='paginationPostCount'>
                <Dropdown
                  options={[10, 25, 50, 100].map((item: number, index: number) => ({ key: index, text: item, value: item, }))}
                  onChange={(e, data) => { console.log(data); setPostsPerPage(Number(data.value)) }}
                  value={postsPerPage}
                />
                {'  '} Posts per page
              </span>
            </div>
            <Table fixed style={{ width: "100%" }}>
              <Table.Header id="logs-table-header" >
                {Object.keys(customTransactionLogView[0]).map((header: string, idx: number) =>
                  <Table.HeaderCell style={{ padding: "auto 2rem" }} id="logs-table-cell" key={idx}>{header.replace(/([A-Z])/g, ' $1')}</Table.HeaderCell>)}
              </Table.Header>
              <Table.Body>
                {displayPosts}
              </Table.Body>
            </Table>
          </div>
        </Grid.Column>
      </Grid>
    </div>
  )
}

export default Logs