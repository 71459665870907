//import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom"
import Login from "./pages/Login";
import NavMenu from "./components/NavMenu";
import Analytics from "./pages/Analytics";
import Dashboard from "./pages/Dashboard";
import Logs from "./pages/Logs";
import Preferences from "./pages/Preferences";
import Reports from "./pages/Reports";
import Support from "./pages/Support";
import Tracking from "./pages/Tracking";
import DashboardMap from './pages/DashboardMap';
import RouteId from './pages/RouteId';
import Carriers from "./pages/Carriers";
import ForgotPassword from "./pages/ForgotPassword";
import FetchGoatNow from "./pages/FetchGoatNow";
import StopList from "./pages/StopList";
import RouteStopTemplate from "./pages/RouteStopTemplate";

function App() {
  return (
    <Routes>
      <Route index path="/" element={<Login />} />
      <Route path="forgotpassword">
        <Route index element={<ForgotPassword />} />
        <Route path=":resetToken" element={<ForgotPassword />} />
      </Route>

      <Route path="/" element={<NavMenu />} >
        <Route index path="Analytics" element={<Analytics />} />
        <Route path="Map" index element={<DashboardMap />} />
        <Route path="Dashboard" element={<Dashboard />} />
        <Route path="Routed">
          <Route index element={<Carriers />} />
          <Route path=":route_id" element={<StopList />} />
        </Route>
        <Route path="FetchGoatNow">
          <Route index element={<FetchGoatNow />} />
          <Route path=":id" element={<RouteId />} />
        </Route>
        <Route path="Logs" element={<Logs />} />
        <Route path="Preferences" element={<Preferences />} />
        <Route path="Reports" element={<Reports />} />
        <Route path="Support" element={<Support />} />
        <Route path="Tracking" element={<Tracking />} />
        <Route path="RouteStopTemplates" element={<RouteStopTemplate />} />
      </Route>
    </Routes>
  );
}

export default App;
