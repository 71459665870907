import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Icon, Form, Loader } from 'semantic-ui-react'
import "../styles/Carriers.scss"
import { routeService } from '../services'
import { RequestPayload } from '../services/ApiClient/types'
import ReactDatePicker from 'react-datepicker'
import SearchTable from '../components/SearchTable';
import { formatDate } from '../utils/datehelper';
import FilterRoutes from '../components/FilterRoutes';

const Carriers = () => {
  const location = useLocation()
  const [loading, setLoading] = useState(true);
  const [noResponse, setNoResponse] = useState(false);
  const [courierData, setCourierData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(location.state === null || undefined ? new Date() : location.state?.preSetSelectedDate || location.state?.preSetSelectedDateFromMapView);
  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true);
    setNoResponse(false);
    setCourierData(null);
    if (localStorage.token === null || localStorage.token === undefined) {
      const navTo = `/`
      navigate(navTo, {
        state: {
          preSetSelectedDate: selectedDate
        }
      })
    }
    else {
      try {
        let payload: RequestPayload<null> = {
          queryParams: {
            pDate: formatDate(selectedDate),
            searchBy: '',
            searchFor: ''
          },
          payload: null
        };
        routeService.GetCourierList(payload)
          .then(response => {
            if (response.success) {
              if (response.result.length === 0) setNoResponse(true)
              else {
                setNoResponse(false)
                setCourierData(response.result);
              }
              setLoading(false);
            }
            else {
              setLoading(false);
            }
          });
      } catch (err) {
        console.log(err)
        setLoading(false);
      }
    }
  }, [selectedDate, navigate]);
  const handleNavigate = (route: string) => {
    const navTo = `/${route}`
    navigate(navTo, {
      state: {
        preSetSelectedDateFromCarrier: selectedDate
      }
    })
  }
  useEffect(() => {
    if (localStorage.token === null) {
      const navTo = `/`
      navigate(navTo)
    }
  }, [navigate]);
  return (
    <div className="background-color">
      <div className='carrier-container'>
        <div className="buttons-container">
          <Button.Group size='big'>
            <Button id='btn-carrier' onClick={() => handleNavigate('Routed')} >
              <Icon name="truck" />Carrier View
            </Button>
            <Button id='btn-routes' onClick={() => handleNavigate('Dashboard')}>
              <Icon name="road" />Routes View
            </Button>
            <Button id='btn-map' onClick={() => handleNavigate('Map')}>
              <Icon name="map" />Map View
            </Button>
          </Button.Group>
        </div>
        {loading && <div>   <Loader active size='massive'>Loading</Loader></div>}
        {noResponse &&
          <div>
            <Form>
              <Form.Group>
                <div id="date-picker" style={{ marginLeft: '50px' }}>
                  <ReactDatePicker selected={selectedDate} onChange={(date) => setSelectedDate(date)} />
                </div>
              </Form.Group>
            </Form>
            <h1>No Carriers For Selected Date</h1>
          </div>}
        {!loading && !noResponse && courierData &&
          <SearchTable setSelectedDate={setSelectedDate} selectedDate={selectedDate} tableData={courierData} excludedColumns={['onTimeIcon', 'onTimeColor', 'topExceptionColor']} />
        }
      </div>
    <FilterRoutes />
    </div>
  )
}
export default Carriers