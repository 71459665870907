import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { analyticsService } from '../services/AnalyticsService';
import { ReportViewModel } from '../services/AnalyticsService/types';
import '../styles/Analytics.scss'
import { Loader } from 'semantic-ui-react';
declare const window: any;

const Analytics = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [noResults, setNoResults] = useState(false);
  const [reportView, setReportView] = useState<ReportViewModel>({ id: "", name: "", embedUrl: "", token: "" });

  useEffect(() => {
    if (localStorage.token === null || localStorage.token === undefined) {
      const navTo = `/`
      navigate(navTo)
    }
    else {
      setNoResults(false);
      try {
        analyticsService.getReportData()
          .then(response => {
            if (response.success) {
              if (response.result !== (null || undefined))
                setReportView(response.result);
              else
                setNoResults(true);
            }
            else {
              console.error('Error pulling back report data', response.message);
            }
          })
      }
      finally {
        setIsLoading(false);
      }
    }
  }, [navigate]);



  return (
    <div className="analytics-container">
      {(() => {
        if (isLoading) {
          return (<div> <Loader inverted active size='massive'>Loading</Loader></div>)
        }
        else {
          if (noResults) {
            return (
              <div style={{ color: 'white', marginTop: '10%'}}>
                <h1> No Power-BI Results</h1>
              </div>
            )
          }
          return (
            <PowerBIEmbed
              embedConfig={{
                type: 'report',
                id: reportView.id,
                embedUrl: reportView.embedUrl,
                accessToken: reportView.token,
                tokenType: models.TokenType.Embed,
                settings: {
                  panes: {
                    filters: {
                      expanded: false,
                      visible: false
                    }
                  },
                }
              }}
              eventHandlers={
                new Map([
                  ['loaded', function () {
                    // console.log('Report loaded');
                  }],
                  ['rendered', function () {
                    // console.log('Report rendered');
                  }],
                  ['error', function (event) {
                    console.log(event.detail);
                  }]
                ])
              }
              cssClassName={"report-style-class"}
              getEmbeddedComponent={
                (embeddedReport) => {
                  window.report = embeddedReport;
                }
              }
            />
          )
        }
      })()}
    </div>


  )
}

export default Analytics